import React, {useState} from 'react';
import './style.scss';
import {groupBy,assign} from 'lodash';
import classnames from 'classnames';
import {useSelector} from "react-redux";
import {selectLamps, selectTime} from "../../redux/selectors";

const lampIsDanger = lamp => lamp.status === 'danger';

export default function LampsGroup({group}) {
  let ts = useSelector(selectTime);
  let lamps = useSelector(selectLamps);
  lamps = lamps.filter(l=>l.group === group.id);
  const [collapsed,setCollapsed] = useState(false);
  const dangerCount = lamps.filter(lampIsDanger).length;

  const timing = {
    'Pre-Arrival': [0,1571244035.288485*1000],
    Arrival: [1571244035.288485*1000,1571244117.8905702*1000],
    Handling: [1571244117.8905702*1000,1571247023.30279*1000],
    Departure: [1571247023.30279*1000,Date.now()]
  };
  let range = timing[group.name];
  const active = ts >= range[0] && ts<range[1];

  return (
    <div className={classnames('group',{active, collapsed})}>
      <i className={'fas fa-circle'}/>
      {!collapsed && !!lamps.length && <div className={'line'}/>}
      <h2>
        {group.name}
        {!!dangerCount && <span className={'badge'}>{dangerCount}</span>}
        <a onClick={()=>setCollapsed(!collapsed)}>
          <i className={classnames('fas',{'fa-caret-down': !collapsed, 'fa-caret-up':collapsed})}/>
        </a>
      </h2>
      {!collapsed && lamps.map(lamp=>
        <div className={classnames('lamp',{active:lamp.status,orange:lampIsDanger(lamp)})} key={lamp.label}>
          <label>{lamp.label}</label>
          <span>{lamp.statusText || 'N/A'}</span>
        </div>
      )}
      <div className={'separator'}/>
    </div>
  )
}