import React from 'react';
import './style.scss';
import logo from './logo.png';

export default function Header() {
  return (
    <header>
      <a href={'https://assaia.com'} target={'_blank'}>
        <img src={logo}/>
      </a>
    </header>
  )
}