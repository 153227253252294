import React from "react";

export default function BBox({bbox}) {
  const {x1,y1,x2,y2} = bbox.bb[0];
  const style ={
    left: x1*100 + '%',
    top: y1*100 + '%',
    width: (x2-x1)*100 + '%',
    height: (y2-y1)*100 + '%'
  };
  return (
    <div className={'bbox'} style={style}>
      <label>{bbox.text}</label>
    </div>
  )
}