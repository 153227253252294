import React from 'react';
import './style.scss';
import {Provider, useSelector} from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import classnames from 'classnames';

import Sidebar from "../Sidebar";
import Header from "../Header";
import Toolbar from "../Toolbar";
import Pane from "../Pane";
import store from "../../redux/store";
import Map from "../Map";
import ContactFormPanel from "../ContactFormPanel";
import logo from '../Sidebar/logo.png';
import { selectStandId } from '../../redux/selectors';
import MobileFormPanel from "../ContactFormPanel/mobileForm";

const mobileWidth = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--mobile-width').trim())
export const isMobile = window.innerWidth <= mobileWidth;
console.log('ismobile',isMobile)

export default function App() {
  if(isMobile)
    return <Provider store={store}><MobileApp/></Provider>

  return (
    <Router>
      <Provider store={store}>
        <div className="app">
          <Header/>
          <Sidebar/>
          <Toolbar/>
          <Switch>
            <Route path={'/'} exact>
              <Map/>
            </Route>
            <Route path={'/520'}>
              <Pane/>
            </Route>
          </Switch>
          <ContactFormPanel/>
        </div>
      </Provider>
    </Router>
  );
}

function MobileApp() {
  const standId = useSelector(selectStandId);

  return (
    <Router>
        <div className="app">
          <MobileFormPanel/>
          <Header/>
          <div className={'mobile-nav'}>
            <NavLink to='/notifications'>
              <i className="fas fa-bell"></i>
              Notifications
            </NavLink>
            <NavLink to={standId ? '/' + standId : '/'} exact>
              <i class="fas fa-shield-alt"></i>
              Overview
            </NavLink>
          </div>
          
          <Switch>
            <Route path={'/'} exact>
              <div className='toolbar mobile-map-toolbar'>
                All gates
              </div>
              <Map/>
              <div className={classnames('sidebar_footer')}>
                <img src={logo}/>
                <span>2020 Assaia International AG</span>
              </div>
            </Route>
            <Route path={'/notifications'} exact>
              <Sidebar/>
            </Route>
            <Route path={'/520'}>
              <Toolbar/>
              <Pane/>
              <div className={classnames('sidebar_footer')}>
                <img src={logo}/>
                <span>2020 Assaia International AG</span>
              </div>
            </Route>
          </Switch>
        </div>
    </Router>
  );
}
