import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import classnames from "classnames";
import {orderBy} from 'lodash';

import './style.scss';
import {selectNotifications, selectTime, selectStandId} from "../../redux/selectors";
import logo from './logo.png';
import logoMin from './logo-min.png';
import Route from "react-router-dom/es/Route";
import {useLocation} from "react-router-dom";

export default function Sidebar() {
  const standId = useSelector(selectStandId);
  const [tab, setTab] = useState(standId || 'all')
  const [collapsed, setCollapsed] = useState(false);

  useEffect(()=>{
    console.log("test",standId)
    setTab(standId ? standId : 'all');
  },[standId])

  const ts = useSelector(selectTime);
  let notifications = useSelector(selectNotifications);
  notifications = notifications.filter(n=>n.timestamp<=ts);
  notifications = orderBy(notifications,['timestamp'],['desc']);

  let allCount = notifications.length;
  let currentCount = notifications.filter(n=>n.gate === "520").length;

  if(tab === "520")
    notifications = notifications.filter(n=>n.gate === "520");

  if(collapsed)
    return (
      <>
        <div className={'sidebar_header collapsed'}>
          <a className={'bell-button'} onClick={()=>collapsed && setCollapsed(false)}>
            <i className="fas fa-bell"></i>
          </a>
        </div>

        <div className={classnames('sidebar_toolbar collapsed')}>
          <h2>All</h2>
        </div>

        <div className={classnames('sidebar_content',{collapsed})}>
          <span className={'badge'}>{notifications.length}</span>
        </div>

        <div className={classnames('sidebar_footer',{collapsed})}>
          <img src={logoMin}/>
        </div>
      </>
    )

  return (
    <>
      <div className={classnames('sidebar_header')}>
        <a className={'bell-button'} onClick={()=>collapsed && setCollapsed(false)}>
          <i className="fas fa-bell"></i>
        </a>
        <h2>Notifications</h2>
        <a onClick={()=>{setCollapsed(!collapsed); setTab('all')}} className={'collapse-btn'}>
          <i className={'fal fa-angle-double-left'}/>
        </a>
      </div>

      <div className={classnames('sidebar_toolbar')}>
        <a className={classnames('tab',{active: tab === "all"})} onClick={()=>setTab("all")}>
          <label>All gates</label>
          <span className={'badge'}>{allCount}</span>
        </a>
        {standId && <a className={classnames('tab',{active: tab === "520"})} onClick={()=>setTab("520")}>
          <label>Current</label>
          <span className={'badge'}>{currentCount}</span>
        </a>}
        <i className={'fas fa-search'}/>
      </div>

      <div className={classnames('sidebar_content')}>
        {notifications.map(notification=>
          <div className={'notification'} key={notification.text+notification.timestamp}>
            <h3>
              <span className={'flight-info'}>
                {notification.gate} {notification.flight}
                {/*<i className="fas fa-long-arrow-alt-up arrow"></i>*/}
              </span>
              <span className={'time'}>
                safety: {moment(notification.timestamp).utc().format('HH:mm')}
              </span>
            </h3>
            <p>
              {notification.text}
            </p>
          </div>
        )}
      </div>

      <div className={classnames('sidebar_footer')}>
        <img src={logo}/>
        <span>2020 Assaia International AG</span>
      </div>
    </>
  )
}