import data from '../data';
import {SET_TIME, SET_STAND} from "./constants";

const initialState = {
  //start: 1571238681000,
  start: 1571243695500,
  speed: 5,
  ...data
};

initialState.timestamp = initialState.start;

initialState.events.forEach(e=>{e.timestamp *=1000;});
initialState.notifications.forEach(e=>{e.timestamp *=1000;});
initialState.bbox.forEach(b=>{b.start *=1000;b.end *=1000;});
initialState.playbackRate.forEach(s=>{s[0] *= 1000;});

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TIME:
      //For Nikolay
      if(process.env.NODE_ENV !== 'development')
        console.log("Set time", action.timestamp/1000);
      return {...state, timestamp: action.timestamp};
    case SET_STAND:
      return {...state, standId: action.id}
    default:
      return state;
  }
}