import React, { useEffect } from 'react';
import './style.scss';
import LampsGroup from "../LampsGroup";
import Video from "../Video";
import {useSelector, useDispatch} from "react-redux";
import {selectGroups} from "../../redux/selectors";
import { SET_STAND } from '../../redux/constants';

export default function Pane() {
  let groups = useSelector(selectGroups);
  let dispatch = useDispatch();
  useEffect(()=>{
    dispatch({type: SET_STAND, id: '520'})
  },[])

  return (
    <div className={'pane'}>
      <div className={'col'}>
        <Video/>
        <label className={'aircraft-model'}>
          A320
        </label>
        <div className={'flight-info'}>
          <span className={'in flight-no'}>BA920</span>
          <span className={'in airport'}>
            osl
            &nbsp;
            <i className="fas fa-arrow-right"/>
          </span>
          <i className="fas fa-circle"/>
          <span className={'out flight-no'}>BA909</span>
          <span className={'out airport'}>
            <i className="fas fa-arrow-right"/>
            &nbsp;
            fra
          </span>
        </div>
      </div>
      <div className={'col'}>
        <div className={'lamps'}>
          {groups.map(g=><LampsGroup group={g} key={g.id}/>)}
        </div>
      </div>
    </div>
  )
}