import {createSelector} from 'reselect'
import {assign, orderBy, findLast} from 'lodash';

const selectState = (s) => s;

export const selectEvents = createSelector(selectState,state=>orderBy(state.events,'timestamp'));

export const selectTime = createSelector(selectState,state=>state.timestamp);

export const selectStandId = createSelector(selectState,state=>state.standId);

export const selectLamps = createSelector(selectState,selectEvents,selectTime,({lamps},events,ts)=>{
  return lamps.map(lamp=>{
    lamp = {...lamp};
    let modifiers = events.filter(e=>e.lampId === lamp.id && e.timestamp<=ts);
    lamp = assign(lamp,...modifiers);
    return lamp;
  });
});

export const selectNotifications = createSelector(selectState,state=>state.notifications);

export const selectBBoxes = createSelector(selectState,selectTime,(state, ts)=>state.bbox.filter(b=>b.start<=ts && b.end >=ts));

export const selectGroups = createSelector(selectState,state=>state.groups);

export const selectStart = createSelector(selectState,state=>state.start);

export const selectSpeed = createSelector(selectState,state=>state.speed);

export const selectPlaybackRate = createSelector(selectState,selectTime,(state,time)=>{
  let rate = findLast(state.playbackRate,r=>time >= r[0]);
  return rate ? rate[1] : 1;
});