import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import classnames from 'classnames';
import KeyHandler, { KEYUP } from 'react-key-handler';

import {SET_TIME} from "../../redux/constants";
import {selectBBoxes, selectSpeed, selectStart, selectTime, selectPlaybackRate} from "../../redux/selectors";
import './style.scss';
import BBox from "./bbox"

function isSafariBrowser(){
  let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
  let is_safari = navigator.userAgent.indexOf("Safari") > -1;
  if (is_safari){
    if (is_chrome)  // Chrome seems to have both Chrome and Safari userAgents
      return false;
    else
      return true;
  }
  return false;
}

export default function Video() {
  const dispatch = useDispatch();
  const start = useSelector(selectStart);
  const speed = useSelector(selectSpeed);
  const timestamp = useSelector(selectTime);
  const boxes = useSelector(selectBBoxes);
  const player = useRef();
  let [videoLength, setVideoLength] = useState(0);

  const playbackRate = useSelector(selectPlaybackRate);
  useEffect(()=>{
    player.current.playbackRate = !isSafariBrowser() ? playbackRate : Math.min(2,playbackRate);
  },[playbackRate])

  const onTimeUpdate = (ev) => {
    dispatch({
      type: SET_TIME,
      timestamp: start + ev.target.currentTime*speed*1000
    });
  };

  const togglePlay = () => {
    if(player.current.paused)
      player.current.play();
    else
      player.current.pause();
  };

  const length = videoLength*1000*speed;
  const progress = (timestamp-start)/length;

  const onProgressClick = (ev) => {
    let rect = player.current.getBoundingClientRect();
    let left = ev.clientX - rect.left;
    player.current.currentTime = player.current.duration*(left/rect.width);
    if(!player.current.paused) {
      player.current.play();
      player.current.pause();
    }
  };

  const onLoadedMetadata = () => {
    setVideoLength(player.current.duration);
    player.current.currentTime = (timestamp - start)/(speed*1000);
    player.current.play();
  };

  const navigate = (delta) => {
    player.current.currentTime += delta;
  };

  const isPlaying = player.current && !player.current.paused;
  return (
    <div className={'player'}>
      <video
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoadedMetadata}
        ref={player}
        playsInline
        muted={true}
        // loop={true}
        src={'/video.mp4'}
      />
      {boxes.map(b=><BBox key={b.id} bbox={b}/>)}
      <KeyHandler
        keyEventName={KEYUP}
        keyCode={37}
        onKeyHandle={()=>{navigate(-1)}}
      />

      <KeyHandler
        keyEventName={KEYUP}
        keyCode={39}
        onKeyHandle={()=>{navigate(1)}}
      />

      <div className={'controls-container'}>
        <div className={'progress-bar'} onClick={onProgressClick}>
          <div className={'progress'} style={{width:progress*100+'%'}}/>
        </div>
        <div className={'controls'}>
          <a
            className={classnames('fas', {'fa-pause': isPlaying, 'fa-play': !isPlaying})}
            onClick={togglePlay}
          />
          <span className={'time'}>
            {moment(timestamp).utc().format("HH:mm:ss")}
            &nbsp;&nbsp;
            {Math.round(progress*100)}%
          </span>
        </div>
      </div>
    </div>
  )
}