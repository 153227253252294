import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import { ReactComponent as MapSVG } from './map3.svg';
import {createPortal} from "react-dom";
import {useHistory} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import {selectTime} from "../../redux/selectors";
import { isMobile } from '../App';
import { SET_STAND } from '../../redux/constants';

function Plane() {
  const history = useHistory();
  const timestamp = useSelector(selectTime);
  const showPlane = timestamp >=1571244095000 && timestamp <= 1571247374000;
  const d = "M13.9,0.4c0.7,0.7,1.1,2.3,1.1,4.7v5.8l10.8,7.3l0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.4v1.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.2-0.6,0.1l-10.3-3.7v6c2.5,1.4,3.8,2.1,3.9,2.2s0.2,0.2,0.2,0.4v1.6c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.1L13,26.2l-5.3,1.5c-0.2,0.1-0.4,0-0.5-0.1C7.1,27.5,7,27.4,7,27.2l0-1.6c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,1.4-0.9,3.9-2.2v-6L0.7,20.7c-0.2,0.1-0.4,0-0.6-0.1C0.1,20.5,0,20.4,0,20.2v-1.6c0-0.1,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.1-0.1l10.8-7.3V5.1c0-2.4,0.4-4,1.1-4.7C12.4,0.1,12.7,0,13,0C13.3,0,13.6,0.1,13.9,0.4z";
  return (
    <g transform={"translate(98, 655)"}>
      {showPlane && <path className="st0" d={d} fill={"white"}/>}
      <rect
        style={{cursor:'pointer'}}
        x1={0} y1={0}
        width={70} height={100}
        fill={'transparent'}
        transform={"translate(-20,-30)"}
        onClick={()=>history.push("/520")}
      />
    </g>

  )
}

export default function Map() {
  const [showPlane,setShowPlane] = useState(false);
  let dispatch = useDispatch();
  useEffect(()=>{
    setShowPlane(true);
    dispatch({type: SET_STAND, id: null});
  },[]);

  console.log("test",isMobile);

  const videoBox = '0 250 400 550';//isMobile ?   '0 250 400 550': '0 0 1321 853';

  return <div className={'map'}>
    <MapSVG viewBox={videoBox} width={null} height={null}/>
    {showPlane && createPortal(<Plane/>,document.querySelector(".map svg"))}
  </div>
}