import React from 'react';
import './style.scss';
import Dropdown from "../Dropdown";
import {Link, NavLink,useHistory,useLocation} from "react-router-dom";
import Switch from "react-router-dom/es/Switch";
import Route from "react-router-dom/es/Route";

export default function Toolbar() {
  const history = useHistory();
  const location = useLocation()
console.log(location.pathname)
  const gates = [
    {label: "519"},
    {label: "520", active: location.pathname ==='/520'},
    {label: "521"},
    {label: "522"},
  ];

  const turns = [
    {label: "BA909 14:30 - in progress", active:true},
    {label: "BA897 13:00 - 14:24"},
    {label: "BA554 12:00 - 12:45"},
  ]

  return (
    <div className={'toolbar'}>

      <Switch>
        <Route path={'/520'}>
          <>
            <Link className={'overview'} to={'/'}>
              <span>Overview</span>
              <i className="fas fa-arrow-right"></i>
              <i className="fas fa-chevron-left"></i>
            </Link>
            <Dropdown
              title={'520'}
              className={'stand-select'}
              options={gates}
              onChange={()=>{}}
            />

            <Dropdown
              title={'BA909 14:30 - in progress'}
              className={'turn-select'}
              onChange={()=>{}}
              options={turns}
            />

            <a className={'export'}>Export</a>
          </>
        </Route>
        <Route path={'/'}>
          <>
            <Link className={'overview'} to={'/'}>
              Overview
              <i className="fas fa-arrow-right"></i>
            </Link>
            <Dropdown
              title={'520'}
              className={'stand-select'}
              options={gates}
              onChange={()=>{history.push('/520')}}
            />
            <a className={'export'}>Export</a>
          </>
        </Route>
      </Switch>
    </div>
  )
}