import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './style.scss';
import ContactForm from "./form";

export default function ContactFormPanel() {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(()=>{
    setTimeout(()=>setCollapsed(false),15000)
  },[]);

  return (
    <div className={classnames('contact-form-panel',{collapsed})}>
      <a className={'toggle-btn'} onClick={()=>setCollapsed(!collapsed)}>
        {!collapsed && <i className={'fal fa-angle-double-right'}/>}
        {collapsed && <i className={'fal fa-angle-double-left'}/>}
        <span>CONTACT</span>
      </a>

      {!collapsed && (
        <ContactForm onSuccess={()=>setCollapsed(false)}/>
      )}
    </div>
  )
}