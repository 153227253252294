import React, {Component, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { isMobile } from '../App';

export default function Dropdown({className, options, title, onChange}) {
  const [opened,setOpened] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const btnRef = useRef();

  useEffect(()=>{
    if(!opened)
      return;

    let rect = btnRef.current.getBoundingClientRect();

    let style = 
      isMobile ?
      {
          top: rect.bottom,
          width: '100%',
          left: 0
      }
      :
      {
        top: rect.bottom + 4,
        minWidth: rect.width,
        left: rect.left
      };

    setMenuStyle(style);
  }, [opened]);

  const close = ()=> {
    setOpened(false);
    document.removeEventListener('click',onDocClick);
  };

  const onDocClick = useCallback(close,[]);

  const select = (val) => {
    close();
    onChange(val);
  };

  const open = () => {
    setOpened(true);
    document.getElementById('root').addEventListener('click',onDocClick);
  };

  return (
    <>
      <a onClick={open} ref={btnRef} className={'dropdown-btn ' + className}>
        <span>{title}</span>
        <i className="fas fa-chevron-down"></i>
      </a>
      {opened && ReactDOM.createPortal(
        <div
          className={'dropdown-menu ' + className}
          style={menuStyle}
          onClick={(ev)=>ev.nativeEvent.stopImmediatePropagation()}
        >
          {options.map(opt=>
            <a key={opt.value} onClick={()=>select(opt.value)} className={opt.active ? 'active':''}>
              {opt.label}
            </a>
          )}
        </div>,
        document.getElementById('root')
      )}
    </>
  );
}